import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { selectClient, selectUser } from 'ui/store/selectors';
import { useStore } from 'ui/store/GenericContext';
import { Link } from 'ui/components/Common';
import { Times } from 'ui/components/Icons';
import { ItemModal } from 'ui/components/PlanDesigns/ItemModals/ItemModal';
import { PlanDesignChallengeItem, ChallengeItem, ChallengeItemType, DeviceableItemTypes, Todo } from 'ui/types';
import * as Modals from './Modals';

export interface State {
  modal: boolean;
  todo: Todo;
  item: PlanDesignChallengeItem | ChallengeItem ;
}

export const Modal: React.FC = () => {
  const { state, dispatch } = useStore<State, any>();
  const { todo, item, modal } = state;
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);

  const dates = () => {
    if (todo.startDate === todo.endDate) {
      return moment(todo.startDate).format('MMMM D, YYYY');
    }

    return `${moment(todo.startDate).format('MMMM D, YYYY')} - ${moment(todo.endDate).format('MMMM D, YYYY')}`;
  };

  let devicesLink: string | null;
  if (client?.featureSettings?.validic) { devicesLink = '/devices'; }
  if (client?.featureSettings?.garmin) { devicesLink = '/garmin_connect'; }

  const renderBody = () => {
    if (todo.type === 'PlanDesign' && !DeviceableItemTypes.includes(todo.itemType)) {
      return <ItemModal item={item as PlanDesignChallengeItem} client={client!} />;
    }

    return (
      <React.Fragment>
        <div className="border-b border-mid-gray pb-4 mb-10">
          <div className="text-title">Record Your Activity</div>
          <div className="text-secondary-title font-serif">{dates()}</div>
        </div>

        <div className="flex flex-col items-center mb-10">
          <div className="text-secondary-title">{todo.challengeTitle}</div>
          <div>{item.longName}</div>
        </div>

        <div data-testid="recordable-modal">
          {!todo.deviceOnly && item.simpleRecording && (
            <div data-testid="simple-recording"><Modals.Simple todo={todo} /></div>
          )}

          {!todo.deviceOnly && !item.simpleRecording && (
            <div data-testid="complex-recording">
              {item.type === ChallengeItemType.Entry && item.unit === 'Text' && <Modals.Text todo={todo} />}
              {item.type === ChallengeItemType.Entry && item.unit !== 'Text' && <Modals.Number item={item} todo={todo} />}
              {item.type === ChallengeItemType.Fitness && <Modals.Fitness todo={todo} />}
              {item.type === ChallengeItemType.Nutrition && <Modals.Nutrition todo={todo} />}
              {item.type === ChallengeItemType.Routine && item.unit === 'Steps' && <Modals.Steps todo={todo} />}
              {item.type === ChallengeItemType.Routine && item.unit === 'Ounces' && <Modals.Water todo={todo} />}
              {item.type === ChallengeItemType.Screening && <Modals.Screening item={item} />}
              {item.type === ChallengeItemType.Sleep && <Modals.Sleep todo={todo} />}
              {item.type === ChallengeItemType.Weight && <Modals.Weight todo={todo} />}
              {item.type === ChallengeItemType.FormResponse && <Modals.FormResponse item={item} />}
            </div>
          )}

          {todo.deviceOnly && (
            <div data-testid="device-only" className="w-full flex flex-col items-center gap-1">
              <div className="text-body font-bold">This item can only be completed through a device.</div>

              {!!devicesLink && (
                <div className="flex gap-1">
                  <Link to={devicesLink}>
                    Click here
                  </Link>
                  <span>to {user!.deviceProfile.devices.length > 0 ? 'manage' : 'connect'} devices.</span>
                </div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };

  if (!modal) { return null; }

  return (
    <div role="dialog">
      <div className="opacity-25 fixed inset-0 z-40 bg-black" />

      <div className="fixed inset-0 z-50 flex items-center justify-center">
        <div className="w-[95%] max-h-[75vh] overflow-y-scroll lg:w-3/5 xl:w-1/2 flex flex-col gap-5 rounded-lg bg-white mx-auto py-5 px-10">
          <div className="lg:mx-10 relative">
            <button
              type="button"
              aria-label="Close Modal"
              className="absolute top-2 right-0"
              onClick={() => dispatch({ type: 'SET_KEY', key: 'modal', payload: false })}
            >
              <Times width="w-[20px]" height="h-[20px]" />
            </button>

            {renderBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
